export const formatDate = (dateString) => {
  // Parse the date string into a Date object
  const date = new Date(dateString);

  // Get day, month, and year from the Date object
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();

  // Format the date as "DD/MM/YYYY"
  return `${day}/${month}/${year}`;
};

export const offerStatus = (used, endDate) => {
  if (used === "Y") {
    return "redeemed";
  } else if (used === "N" && new Date(endDate.replace(" ", "T")) > new Date()) {
    return "available";
  } else if (used === "N" && new Date(endDate.replace(" ", "T")) < new Date()) {
    return "expired";
  }
};

export const remainingDateToExpire = (endDateStr) => {
  const endDate = new Date(endDateStr.replace(" ", "T"));

  const currentDate = new Date();

  const differenceInMs = endDate - currentDate;

  const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
  return differenceInDays;
};
