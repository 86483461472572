import React, { useEffect, useState } from "react";
import { offerImages } from "../offerSDKAssets/offerSDKImages/OfferSDKImages";
import { formatDate, remainingDateToExpire } from "./OfferLayoutUtil";

const OfferLayoutGrid = ({ offer: offer }) => {
  const [expireSoon, setExpireSoon] = useState(false);
  useEffect(() => {
    let endDate =
      offer?.offerMemberGroupDetailList?.offerMemberGroupDetail[0]
        ?.registrationEndDate;
    if (offer.used === "N" && remainingDateToExpire(endDate) <= 5 && remainingDateToExpire(endDate) >= 0) {
      setExpireSoon(true);
    } else {
      setExpireSoon(false);
    }
  }, [offer]);
  return (
    <div className={`_offer_container ${expireSoon ? "_offer_expire_soon" : ""}`}>
      {expireSoon && <div class="_offer_expire_soon_tag">Expires Soon</div>}
      <div className="_offer_image_container">
        <img src={offer?.imageName} alt="" />
      </div>
      <div className="_offer_details">
        <div className="_offer_details_container">
          <h3 className="_offer_details_title">{offer?.offerName}</h3>
          <p>
            <span className="_offer_details_description">{offer?.description}</span>
          </p>
          <p>
            <span className="_offer_discount_label">{"Discount"} : </span>
            <span className="_offer_discount_value">{"10% off"}</span>
          </p>
          <p className="_offer_details_expires_label">Expires on <span className="_offer_details_expires_value">{formatDate(offer?.offerMemberGroupDetailList?.offerMemberGroupDetail[0]
            ?.registrationEndDate)}</span></p>
        </div>
        <div className="_offer_container_footer">
          <button className="_offer_btn _offer_btn_primary">{"Redeem"}</button>
        </div>
      </div>
    </div>
  );
};

export default OfferLayoutGrid;
