import birthdayImage from "./birthdayoffer.png";
import christmasImage from "./christmas.png";
import newyearOffer from "./newyearoffer.png";
import gift from "./gift.png";
import offerImage from "./offerImage.png";
export const offerImages = {
    birthdayImage,
    christmasImage,
    newyearOffer,
    gift,
    offerImage
}
