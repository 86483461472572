import {
	callIcon,
	offerIcon,
	dashboardIcon,
	offerCreateIcon,
	offerDashboardIcon,
	offerListIcon,
	percentageIcon,
	usersIcon,
	walletIcon,
	locationIcon,
	segmentationIcon,
	configureIcon,
	attributeIcon,
	loyaltyIcon,
	triggersIcon,
	datasetsIcon,
	campaignIcon,
	campaignCreateIcon,
	createContentIcon,
	subClubIcon,
	viewSegmentsIcon,
	viewCampaignIcon,
	reportsIcon,
	connectorIcon,
} from "../utilsComponent/SidebarIcons";
import {
	DETAILS,
	DASHBOARD,
	OFFER,
	VIEW_OFFER,
	SEGMENTS,
	VIEW_SEGMENT,
	CONFIGURE,
	ATTRIBUTE,
	CAMPAIGN,
	VIEW,
	CONTENT,
	VIEW_CAMPAIGN,
	EDIT_SEGMENT,
	CUSTOM,
} from "./PathConstants";
import { MdDashboard, MdOutlineLocalOffer } from "react-icons/md";
import { SlBadge } from "react-icons/sl";
import { TiLocationOutline } from "react-icons/ti";
import { FaWallet } from "react-icons/fa";
import { AiFillFileAdd } from "react-icons/ai";
import CampaignDashboardWrapper from "../campaignSetupComponent/campaignDashboardComponent/CampaignDashboardWrapper";
import CampaignListComponent from "../campaignSetupComponent/campaignListComponent/CampaignListComponent";
import CampaignWrapper from "../campaignSetupComponent/campaignWrapperComponent/CampaignWrapper";
import ContentListComponent from "../contentSetupComponent/contentListComponent/ContentListComponent";
import ContentWrapper from "../contentSetupComponent/contentWrapperComponent/ContentWrapper";
import DashboardWrapper from "../offerSetupComponent/OfferDashboardComponent/DashboardWrapper";
import PageWrapper from "../offerSetupComponent/offerWrapperComponent/OfferWrapper";
import TableView from "../offerSetupComponent/viewOfferComponent/TableView";
import SegmentWrapper from "../segmentSetupComponent/segmentWrapperComponent/SegmentWrapper";
import SegmentView from "../segmentSetupComponent/viewSegmentsComponent/SegementTableView";
import AttributeListComponent from "../attributeComponent/attributeListComponent/AttributeListComponent";
import SubclubWrapper from "../subclubComponent/subclubWrapperComponent/SubclubWrapper";
import AttributeWrapper from "../attributeComponent/attributeWrapperComponent/AttributeWrapper";
import CustomOfferWrapper from "../offerSetupComponent/customOfferWrapperComponent/CustomOfferWrapper";
import {
	ACTIVATE_CONFIRMATION_MESSAGE,
	DEACTIVATE_CONFIRMATION_MESSAGE,
	SAVE_AND_ACTIVATE_CONFIRMATION_MESSAGE,
	START_AB_TEST_CONFIRMATION_MESSAGE,
	START_AB_TEST_MESSAGE,
} from "./MessageConstant";
import {
	checkRightsIsVisible,
	getAllRights,
	getRightsKey,
} from "../utilsComponent/CommonUtil";
import ReportsWrapper from "../reportsSetupComponent/reportsWrapper/ReportsWrapper";
import ConnectorsWrapperComponent from "../connectorsSetupComponent/connectorsWrapperComponent/ConnectorsWrapperComponent";
export const blackColor = "#000000";
export const activeLinkColor = "#fff";
export const disabledLinkColor = "#bbbbbb";
export const linkStrokeColor = "green";
export const DUAL_NAVLINKS = [
	{
		id: "nav-loy-1",
		label: "Loyalty",
		isActive: false,
		rights: ["navigation", "loyalty"],
		isCompleted: false,
		icon: loyaltyIcon,
		isDisabled: false,
		url: OFFER,
		class: "small",
		isVisible: true,
		activeOption: [OFFER],
		subLinks: [
			{
				id: "nav-loy-dash-1",
				label: "Dashboard",
				rights: ["navigation", "loyalty", "dashboard"],
				isActive: true,
				icon: dashboardIcon,
				url: OFFER + DASHBOARD,
				path: DASHBOARD,
				Component: { Name: DashboardWrapper },
				path: DASHBOARD,
				class: "mid",
				isVisible: true,
				isRoute: true,
				activeOption: [OFFER + DASHBOARD],
			},
			{
				id: "nav-loy-co-2",
				label: "Create Offer",
				rights: ["navigation", "loyalty", "createOffer"],
				isActive: false,
				icon: offerCreateIcon,
				url: OFFER + DETAILS,
				path: DETAILS,
				Component: { Name: PageWrapper },
				class: "large",
				isVisible: true,
				activeOption: [OFFER + DETAILS, OFFER + CUSTOM + DETAILS],
				isRoute: true,
			},
			{
				id: "nav-loy-vo-3",
				label: "View Offers",
				rights: ["navigation", "loyalty", "viewoffer"],
				isActive: false,
				icon: offerListIcon,
				url: OFFER + VIEW_OFFER,
				path: VIEW_OFFER,
				Component: { Name: TableView },
				class: "large",
				isVisible: true,
				activeOption: [
					OFFER + VIEW_OFFER,
					OFFER + DETAILS,
					OFFER + CUSTOM + DETAILS,
				],
				isRoute: true,
			},

			{
				id: "nav-loy-cuo-4",
				label: "Custom Offers",
				isActive: false,
				icon: offerListIcon,
				url: OFFER + CUSTOM + DETAILS,
				path: CUSTOM + DETAILS,
				Component: { Name: CustomOfferWrapper },
				class: "large",
				rights: ["navigation", "loyalty", "custom", "createoffer"],
				isVisible: false,
				activeOption: [OFFER + CUSTOM + DETAILS],
				isRoute: true,
			},
		],
	},
	{
		id: "nav-seg-2",
		label: "Segmentation",
		rights: ["navigation", "segmentation"],
		isActive: false,
		isCompleted: false,
		icon: segmentationIcon,
		isDisabled: false,
		url: SEGMENTS,
		class: "large",
		isVisible: true,
		activeOption: [SEGMENTS],
		subLinks: [
			{
				id: "nav-seg-cs-1",
				label: "Create Segment",
				rights: ["navigation", "segmentation", "createsegment"],
				isActive: false,
				icon: offerCreateIcon,
				url: SEGMENTS + DETAILS,
				path: DETAILS,
				Component: { Name: SegmentWrapper },
				class: "large",
				isVisible: true,
				isRoute: true,
				activeOption: [SEGMENTS + DETAILS],
			},
			{
				id: "nav-seg-vs-2",
				label: "View Segments",
				rights: ["navigation", "segmentation", "viewsegmentation"],
				isActive: true,
				icon: viewSegmentsIcon,
				url: SEGMENTS + VIEW_SEGMENT,
				path: VIEW_SEGMENT,
				Component: { Name: SegmentView },
				class: "large",
				isVisible: true,
				isRoute: true,
				activeOption: [SEGMENTS + VIEW_SEGMENT, SEGMENTS + DETAILS],
			},
			{
				id: "nav-seg-es-3",
				label: "Edit Segments",
				isActive: false,
				icon: offerListIcon,
				path: EDIT_SEGMENT,
				Component: { Name: SegmentWrapper },
				class: "large",
				isVisible: false,
				isRoute: true,
				activeOption: [EDIT_SEGMENT],
			},
		],
	},
	{
		id: "nav-conf-3",
		label: "Configure",
		rights: ["navigation", "configure"],
		isActive: false,
		icon: configureIcon,
		isDisabled: false,
		url: CONFIGURE,
		class: "mid",
		isVisible: true,
		activeOption: [CONFIGURE],
		subLinks: [
			{
				id: "nav-conf-attr-1",
				label: "Attribute",
				rights: ["navigation", "configure", "attribute"],
				isActive: false,
				icon: attributeIcon,
				url: CONFIGURE + ATTRIBUTE,
				path: VIEW,
				Component: { Name: AttributeListComponent },
				class: "mid",
				isVisible: true,
				isRoute: true,
				activeOption: [
					CONFIGURE + ATTRIBUTE,
					CONFIGURE + ATTRIBUTE + DETAILS,
					CONFIGURE + ATTRIBUTE + VIEW,
				],
			},
			{
				id: "nav-conf-av-2",
				label: "Attribute",
				isActive: true,
				icon: attributeIcon,
				url: CONFIGURE + ATTRIBUTE + VIEW,
				path: VIEW,
				Component: { Name: AttributeListComponent },
				class: "mid",
				isVisible: false,
				isRoute: true,
				activeOption: [CONFIGURE + ATTRIBUTE + VIEW],
			},
			{
				id: "nav-conf-subclub-3",
				label: "Subclub",
				rights: ["navigation", "configure", "subclub"],
				isActive: false,
				icon: subClubIcon,
				url: CONFIGURE + "/subclub",
				path: "/subclub",
				Component: { Name: SubclubWrapper },
				class: "mid",
				isVisible: true,
				subLinks: [],
				isRoute: true,
				activeOption: [CONFIGURE + "/subclub"],
			},
			{
				id: "nav-conf-ca-4",
				label: "Create Attribute",
				rights: ["navigation", "configure", "attribute", "createattribute"],
				isActive: false,
				icon: subClubIcon,
				path: DETAILS,
				Component: { Name: AttributeWrapper },
				class: "mid",
				isVisible: false,
				subLinks: [],
				isRoute: true,
				activeOption: [CONFIGURE + ATTRIBUTE + DETAILS],
			},
			{
				id: "nav-conf-connectors-3",
				label: "Connector",
				rights: ["navigation", "configure", "subclub"],
				isActive: false,
				icon: connectorIcon,
				url: CONFIGURE + "/connector",
				path: "/connector",
				Component: { Name: ConnectorsWrapperComponent },
				class: "mid",
				isVisible: true,
				subLinks: [],
				isRoute: true,
				activeOption: [CONFIGURE + "/connector"],
			},
			{
				id: "nav-conf-vo-4",
				label: "Offers",
				rights: ["navigation", "configure", "subclub"],
				isActive: false,
				icon: offerListIcon,
				url: CONFIGURE + "/offers",
				path: "/offers",
				Component: { Name: TableView },
				class: "small",
				isVisible: true,
				subLinks: [],
				isRoute: true,
				activeOption: [CONFIGURE + "/offers"],
			},
		],
	},
	{
		id: "nav-camp-4",
		label: "Campaigns",
		rights: ["navigation", "campaign"],
		isActive: false,
		isCompleted: false,
		icon: campaignIcon,
		isDisabled: false,
		url: CAMPAIGN,
		class: "large",
		isVisible: true,
		activeOption: [CAMPAIGN],
		subLinks: [
			{
				id: "nav-camp-dash-1",
				label: "Dashboard",
				rights: ["navigation", "campaign", "dashboard"],
				isActive: true,
				icon: offerDashboardIcon,
				url: CAMPAIGN + DASHBOARD,
				path: DASHBOARD,
				Component: { Name: CampaignDashboardWrapper },
				class: "mid",
				isVisible: true,
				isRoute: true,
				activeOption: [CAMPAIGN + DASHBOARD],
			},
			{
				id: "nav-camp-camp-2",
				label: "Campaign",
				rights: ["navigation", "campaign", "campaign"],
				isActive: false,
				icon: viewCampaignIcon,
				url: CAMPAIGN + VIEW,
				path: VIEW_CAMPAIGN,
				Component: { Name: CampaignListComponent },
				class: "mid",
				isVisible: true,
				isRoute: true,
				activeOption: [CAMPAIGN + VIEW, CAMPAIGN + DETAILS],
			},
			{
				id: "nav-camp-cc-3",
				label: "Create Campaign",
				rights: ["navigation", "campaigns", "createcampaign"],
				isActive: false,
				icon: offerListIcon,
				path: DETAILS,
				Component: { Name: CampaignWrapper },
				class: "mid",
				isVisible: false,
				isRoute: true,
				activeOption: [CAMPAIGN + DETAILS],
			},
			{
				id: "nav-camp-cont-4",
				label: "Content",
				rights: ["navigation", "campaign", "content"],
				isActive: false,
				icon: createContentIcon,
				url: CAMPAIGN + CONTENT,
				path: CONTENT,
				Component: { Name: ContentListComponent },
				class: "small",
				isVisible: true,
				isRoute: true,
				activeOption: [CAMPAIGN + CONTENT, CAMPAIGN + CONTENT + DETAILS],
			},
			{
				id: "nav-camp-crc-5",
				label: "Create Content",
				rights: ["navigation", "campaign", "content", "createcontent"],
				isActive: false,
				icon: createContentIcon,
				path: CONTENT + DETAILS,
				Component: { Name: ContentWrapper },
				class: "small",
				isVisible: false,
				isRoute: true,
				activeOption: [CAMPAIGN + CONTENT + DETAILS],
			},
		],
	},
	// {
	//   id: "nav-report-5",
	//   label: "Reports",
	//   rights: ["navigation", "reports"],
	//   isActive: false,
	//   isCompleted: false,
	//   icon: reportsIcon,
	//   isDisabled: false,
	//   url: "/reports",
	//   class: "large",
	//   isVisible: true,
	//   activeOption: ["/reports"],
	//   subLinks: [
	//     {
	//       id: "nav-report-dash-1",
	//       label: "Dashboard",
	//       rights: ["navigation", "reports", "dashboard"],
	//       isActive: true,
	//       icon: offerDashboardIcon,
	//       url: "/reports" + DASHBOARD,
	//       path: DASHBOARD,
	//       Component: { Name: ReportsWrapper },
	//       class: "mid",
	//       isVisible: true,
	//       isRoute: true,
	//       activeOption: ["/reports" + DASHBOARD],
	//     },
	//   ],
	// },
];
export const CREATE_OFFER_LINK = "Create Offer";
export const VIEW_OFFER_LINK = "View Offer";
export const TOASTER_ERROR = "ERROR";
export const TOASTER_SUCCESS = "SUCCESS";

export const OFFER_DETAILS_STATUS_DRAFT = "DRAFT";
export const OFFER_DETAILS_STATUS_ACTIVATED = "ACTIVATED";
export const OFFER_DETAILS_STATUS_DEACTIVATED = "DEACTIVATED";
export const OFFER_DETAILS_STATUS_CHANGE_SUCCESS = "SUCCESS";
export const OFFER_DETAILS_STATUS_AB_TEST = "AB-TESTING";

export const OFFER_DETAILS_STATUS_DRAFT_MESSAGE = "Draft";
export const OFFER_DETAILS_STATUS_ACTIVATED_MESSAGE = "Active";
export const OFFER_DETAILS_STATUS_DEACTIVATED_MESSAGE = "Inactive";
export const OFFER_DETAILS_STATUS_DEACTIVATED_EXPIRED_MESSAGE = "Expired";

export const offer_status = {
	DRAFT: "Draft",
	ACTIVATED: "ACTIVE",
	DEACTIVATED: "Inactive",
	ACTIVE: "ACTIVE",
};
export const CREATE_SEGMENT_LINK = "Create Segment";
export const VIEW_SEGMENT_LINK = "View Segments";

export const headers = {
	"Content-Type": "application/json",
	accept: "application/json",
	// requestAccess: "sec_offerdetails_access~0~0",
	//orgName:"acme"
};
export const RequestAccess = "sec_offerdetails_access~0~0";
export const uploadFileHeader = {
	"Content-Type": "multipart/form-data",
	accept: "application/json",
	// requestAccess: "sec_offerdetails_access~0~0",
	//orgName:"acme"
};
export const OFRI = "OFRI";
export const ESLO = "ESLO";
export const OFRP = "OFRP";
export const SAVE = "Save";
export const ACTIVATE = "Activate";
export const DEACTIVATE = "Deactivate";
export const EDIT = "Edit";
export const COMING_SOON = "Coming Soon";
export const ABTEST = "Start AB Test";

// export const organizations = {
//   ffb: "ESLO",
//   raise: "ESLO",
//   syw: "ESLO",
//   acme: "acme",
//   eslo: "ESLO",
// };
// export const orgId = {
//   ffb: "org_Qpo44fKP9T3PgkT8",
//   //raise: "ESLO",
//   //syw: "ESLO",
//   acme: "org_8yGJcCaj78QRuaZd",
//   //eslo: "ESLO",
// };
export const validOrg = ["login-callback", "auth", "auth0_org", "offerlayout"];
export const SKEY = "skey";
export const RIGHTS = "rights";
export const REQUEST_ACCESS = "requestAccess";
export const SUCCESS = "success";
export const LIST = "list";
export const STRING = "String";
export const NUMBER = "number";
export const DATE = "date";
export const DISABLED = "disabled";
export const ENABLED = "enabled";
export const SEGMENT = "SEGMENT";
export const OFFER_TITLE = "OFFER";
export const CONTENT_TITLE = "CONTENT";
export const CAMPAIGN_TITLE = "CAMPAIGN";
export const MONTHS = [
	"Jan",
	"Feb",
	"March",
	"Apr",
	"May",
	"June",
	"July",
	"Aug",
	"Sept",
	"Oct",
	"Nov",
	"Dec",
];
export const PRICE_DISCOUNT = "Price Discount";
export const POINTS_EARN = "Points Earn";
export const BASE_EARN = "Base Earn";
export const XTREME_REDEEM = "Xtreme Redeem";
export const BASE_REDEEM = "Base Redeem";
export const NON_PURCHASE = "NonPurchase PointsEarn";
export const LOYALTY_CASH = "Loyalty Cash";
export const MISSION = "Mission";
export const XR = "XR";
export const BASE = "BASE";
export const EVENTPROC = "EVENTPROC";
export const EVENTPROCESSING = "EventProcessing";
export const EVENT_OFFER = "Event Offer";
export const CANCEL = "Cancel";
export const FILE_UPLOAD_SUCCESSULL_TEXT = "File uploaded successfully";
export const PROCESSING_LOADER = "Processing...";
export const CONTINUE_TO_SETUP = "Continue to setup";
export const DARK = "dark";
export const LIGHT = "light";
export const DROPDOWN_SELECTED_BGCOLOR_LIGHT_THEME = "#393854";
export const DROPDOWN_SELECTED_BGCOLOR_DARK_THEME = "#4d4c4c";
export const DROPDOWN_FOCUSED_BGCOLOR_DARK_THEME = "grey";
export const DROPDOWN_FOCUSED_BGCOLOR_LIGHT_THEME = "#c6c6d345";
export const DROPDOWN_BGCOLOR_DARK_THEME = "#363636";
export const EMPTY = "";
export const CURSOR_POINTER = "cursor-pointer";
export const CUSTOM_OFFER = "Custom Offer";
export const WEEKS = [
	"sunday",
	"monday",
	"tuesday",
	"wednesday",
	"thursday",
	"friday",
	"saturday",
];
export const EN_US = "en-US";

export const SAVE_WINNER_CAMPAIGN_STATUS = [
	OFFER_DETAILS_STATUS_AB_TEST,
	OFFER_DETAILS_STATUS_DEACTIVATED,
];
export const campaignPopupJson = {
	activate: {
		headerText: "",
		bodyText: ACTIVATE_CONFIRMATION_MESSAGE,
		bodyTextSave: SAVE_AND_ACTIVATE_CONFIRMATION_MESSAGE,
		buttonSecondary: "",
		button: "Yes, activate it",
		buttonSave: "Save and Activate",
		status: "ACTIVATED",
		clickedBtnKey: ACTIVATE,
	},
	deactivate: {
		headerText: "",
		bodyText: DEACTIVATE_CONFIRMATION_MESSAGE,
		buttonSecondary: "No",
		button: "Yes, deactivate it",
		status: "DEACTIVATED",
		clickedBtnKey: DEACTIVATE,
	},
	startAbTest: {
		headerText: "",
		bodyText: START_AB_TEST_MESSAGE,
		bodyTextSave: START_AB_TEST_CONFIRMATION_MESSAGE,
		buttonSecondary: "",
		button: "Yes, Start AB Testing",
		buttonSave: "Save & Start AB Testing",
		status: "AB-TESTING",
		clickedBtnKey: ABTEST,
	},
};

export const abbreviationMap = {
	account: "acc",
	activate: "act",
	analysis: "anal",
	application: "app",
	attribute: "attr",
	authentication: "auth",
	baseearn: "be",
	baseredeem: "br",
	button: "btn",
	campaign: "cmp",
	communication: "com",
	configuration: "conf",
	create: "crt",
	createOffer: "cre_ofr",
	customoffer: "co",
	dashboard: "db",
	deactivate: "deact",
	delete: "del",
	document: "doc",
	edit: "edt",
	information: "info",
	inactive: "inact",
	link: "lnk",
	list: "lst",
	loyalty: "loy",
	loyaltycash: "lc",
	management: "mgmt",
	missionoffer: "mo",
	navigation: "nav",
	notification: "notif",
	nonpurchasepointsearn: "nppe",
	offer: "ofr",
	pointsearn: "pe",
	popup: "pop",
	pricediscount: "pd",
	save: "sav",
	segmentation: "seg",
	secure: "sec",
	setup: "stp",
	status: "stat",
	subclub: "subc",
	system: "sys",
	update: "upd",
	view: "vw",
};

export const TYPE = "type";
export const REDUX = "redux";
export const LOCAL = "local";
export const SESSION = "session";
export const REPORT_REQUEST_ACCESS = "ext_nav_rep_db~0~0";
