import { offerImages } from "../offerSDKAssets/offerSDKImages/OfferSDKImages";
export const tabs = [
  {
    title: "Available",
    value: "available",
  },
  {
    title: "Redeemed",
    value: "redeemed",
  },
  {
    title: "Expired",
    value: "expired",
  },
];
export const offerLayoutData = {
  offer: [
    {
      offerNumber: "EqualsplitCars",
      description: "Equalsplit_Cars",
      used: "Y",
      category: "",
      programType: "",
      endDTTM: "2024-06-25T23:59:59.0",
      startDTTM: "2024-06-23T00:00:00.0",
      offerName: "Equalsplit_Cars",
      programSubType: "",
      pointAvailableTs: "TransactionDate plus 0 DAY",
      pointExpiryTs: "TransactionDate plus 10 DAY",
      imageName: offerImages.offerImage,
      terms: "",
      subCategory: "",
      offerMemberGroupDetailList: {
        offerMemberGroupDetail: [
          {
            groupName: "Equalsplit_Cars",
            registrationStartDate: "2024-06-23 00:00:00",
            registrationEndDate: "2024-08-15 23:59:59",
            additionalGroupInfo: "",
            registrationSeqNumber: "1",
          },
        ],
      },
    },
    {
      offerNumber: "99769-1",
      description: "Decs:3xSurpriseOffer_Cars test",
      used: "N",
      category: "",
      programType: "",
      endDTTM: "2024-07-25T23:59:59.0",
      startDTTM: "2024-06-25T00:00:01.0",
      offerName: "3xSurpriseOffer_Cars",
      programSubType: "",
      pointAvailableTs: "TransactionDate plus 0 DAY",
      pointExpiryTs: "TransactionDate plus 0 CALDAY",
      imageName: offerImages.birthdayImage,
      terms: "",
      subCategory: "",
      offerMemberGroupDetailList: {
        offerMemberGroupDetail: [
          {
            groupName: "99769",
            registrationStartDate: "2024-06-25 00:00:01",
            registrationEndDate: "2024-08-28 23:59:59",
            additionalGroupInfo: "",
            registrationSeqNumber: "1",
          },
        ],
      },
    },
    {
      offerNumber: "99768",
      description: "EarnSpendthresh_CARs",
      used: "N",
      category: "",
      programType: "",
      endDTTM: "2024-07-25T23:59:59.0",
      startDTTM: "2024-06-25T00:00:01.0",
      offerName: "EarnSpendthresh_CARs",
      programSubType: "",
      pointAvailableTs: "TransactionDate plus 0 DAY",
      pointExpiryTs: "TransactionDate plus 2 WEEK",
      imageName: offerImages.christmasImage,
      terms: "",
      subCategory: "",
      offerMemberGroupDetailList: {
        offerMemberGroupDetail: [
          {
            groupName: "99768",
            registrationStartDate: "2024-06-25 00:00:01",
            registrationEndDate: "2024-07-25 23:59:59",
            additionalGroupInfo: "",
            registrationSeqNumber: "1",
          },
        ],
      },
    },
    {
      offerNumber: "99769-1",
      description: "Decs:3xSurpriseOffer_Cars test",
      used: "N",
      category: "",
      programType: "",
      endDTTM: "2024-07-25T23:59:59.0",
      startDTTM: "2024-06-25T00:00:01.0",
      offerName: "3xSurpriseOffer_Cars",
      programSubType: "",
      pointAvailableTs: "TransactionDate plus 0 DAY",
      pointExpiryTs: "TransactionDate plus 0 CALDAY",
      imageName: offerImages.newyearOffer,
      terms: "",
      subCategory: "",
      offerMemberGroupDetailList: {
        offerMemberGroupDetail: [
          {
            groupName: "99769",
            registrationStartDate: "2024-06-25 00:00:01",
            registrationEndDate: "2024-08-02 23:59:59",
            additionalGroupInfo: "",
            registrationSeqNumber: "1",
          },
        ],
      },
    },
    {
      offerNumber: "99768",
      description: "EarnSpendthresh_CARs",
      used: "N",
      category: "",
      programType: "",
      endDTTM: "2024-07-25T23:59:59.0",
      startDTTM: "2024-06-25T00:00:01.0",
      offerName: "EarnSpendthresh_CARs",
      programSubType: "",
      pointAvailableTs: "TransactionDate plus 0 DAY",
      pointExpiryTs: "TransactionDate plus 2 WEEK",
      imageName: offerImages.gift,
      terms: "",
      subCategory: "",
      offerMemberGroupDetailList: {
        offerMemberGroupDetail: [
          {
            groupName: "99768",
            registrationStartDate: "2024-06-25 00:00:01",
            registrationEndDate: "2024-08-15 23:59:59",
            additionalGroupInfo: "",
            registrationSeqNumber: "1",
          },
        ],
      },
    },
  ],
};
