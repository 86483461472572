import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import AuthLoading from "../../LoaderComponents/AuthLoading";
import Header from "../../headerComponent/Header";
import NavigationBar from "../../navigationBarComponent/internalNavigationBarComponent/NavigationBar";
import { ErrorBoundary } from "react-error-boundary";
import { Route, Routes, Navigate } from "react-router-dom";
import { PageNotFound } from "../../unauthorizeComponent/UnauthorizeWrapper";
import { DUAL_NAVLINKS } from "../../constantComponent/Constants";
import SessionExpiredConfirmation from "../../modelComponent/sessionExpiredConfirmationComponent/SessionExpiredConfirmation";

function ReportsRedirection() {
  const ReportSessionExpire = useSelector(
    (state) => state.reportTokenData.showExpiredSessionPopup
  );
  const scrollTargetDiv = useRef();

    const toasterData = useSelector((state) => state.toasterData);
  const [theme, setTheme] = useState(toasterData.isDarkTheme);
  return (
    <div data-theme={theme}>
      <AuthLoading>
        <div className="redirection-page-wrapper">
          <Header title="Reports" />
          <section className="display-flex-wrapper" ref={scrollTargetDiv}>
            <NavigationBar
            //   showOfferPopup={showOfferPopup}
            //   setShowOfferPopup={setShowOfferPopup}
              // selectOffer={selectOffer}
              // setSelectOffer={setSelectOffer}
            //   src={SEGMENT}
            />
            {/* <AnimatePresence>
              {showOfferPopup && (
                <CreateSegmentComponent
                  // selectOffer={selectOffer}
                  setSelectOffer={setSelectOffer}
                  // showOfferPopup={showOfferPopup}
                  setShowOfferPopup={setShowOfferPopup}
                />
              )}
            </AnimatePresence> */}
            <div className="inner-section-wrapper">
              <ErrorBoundary FallbackComponent={Error} onError={(error) => {}}>
                <Routes>
                  {DUAL_NAVLINKS.find(
                    (dualNavLink) => dualNavLink.label === "Reports"
                  ).subLinks.map((SubLink) => {
                    if (SubLink.isRoute) {
                      return (
                        <>
                          <Route
                            path={SubLink.path}
                            element={<SubLink.Component.Name />}
                          />
                          {SubLink.isActive && (
                            <Route
                              path="/"
                              element={
                                <Navigate
                                  to={
                                    "/" +
                                    window.location.pathname.split("/")[1] +
                                    SubLink.url
                                  }
                                  replace
                                />
                              }
                            />
                          )}
                        </>
                      );
                    }
                  })}
                  <Route
                    path="*"
                    Component={() => {
                      return <PageNotFound pageName={"Page not found !"} />;
                    }}
                  />
                </Routes>
              </ErrorBoundary>
            </div>
          </section>
        </div>
        {/* <LeavingSetupPageConfirmationComponent /> */}
        {ReportSessionExpire=== true ? (
          <SessionExpiredConfirmation />
        ) : null}
      </AuthLoading>
    </div>
  );
}

export default ReportsRedirection;
