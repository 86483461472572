import React from "react";
import { createRoot } from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Auth0ProviderWithNavigate } from "../src/security/auth0-provider-with-navigate";
import { CallbackPage } from "../src/security/pages/callback-page";
import { AuthGuard } from "./security/components/AuthGuard";
import "./tellurideExtAssets/styles/Styles.style.scss";
import {
  DASHBOARD,
  OFFER,
} from "./tellurideExtComponents/constantComponent/PathConstants";
import Error from "./tellurideExtComponents/errorsComponent/Error";
import Toaster from "./tellurideExtComponents/errorsComponent/Toaster";
import ConfigureRedirection from "./tellurideExtComponents/redirectionComponent/configureRedirectionComponent/ConfigureRedirection";
import OfferRedirection from "./tellurideExtComponents/redirectionComponent/offerRedirectionComponent/OfferRedirection";
import SegmentRedirection from "./tellurideExtComponents/redirectionComponent/segmentRedirectionComponent/SegmentRedirection";
import CampaignRedirection from "./tellurideExtComponents/redirectionComponent/campaignRedirectionComponent/CampaignRedirection";
import { PageNotFound } from "./tellurideExtComponents/unauthorizeComponent/UnauthorizeWrapper";
import store from "./tellurideExtRedux/store";
import ReportsRedirection from "./tellurideExtComponents/redirectionComponent/reportsRedirectionComponent/ReportsRedirection";
import UnauthorizeWrapper from "./tellurideExtComponents/unauthorizeComponent/UnauthorizeWrapper";
import OfferLayoutWrapper from "./tellurideOfferSDKComponents/offerLayoutWrapper/OfferLayoutWrapper";
import ChatBotWidget from "./tellurideExtComponents/utilsComponent/ChatBot/ChatBotWidget";
function getChatbotQryParamShowStatus(){
  let chatbot_display_localstorage_val = localStorage.getItem("chatbot_display_param_status");
  if(chatbot_display_localstorage_val === null){
    let queryParamVal = queryString.parse(window.location.search).showchat;
    if(queryParamVal !== undefined)
      localStorage.setItem("chatbot_display_param_status", queryParamVal);
    
    return queryParamVal;
  }
  return chatbot_display_localstorage_val;
}
const chatbotAlwaysShowStatus = process.env.REACT_APP_CHATBOT_SHOW_ALWAYS;
const queryString = require("query-string");
const chatShowQryParam = getChatbotQryParamShowStatus();
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Auth0ProviderWithNavigate>
        <ErrorBoundary FallbackComponent={Error} onError={(error) => {}}>
          <Routes>
            <Route path="/offerlayout" element={<OfferLayoutWrapper />} />
            <Route
              path="/login-callback"
              element={<AuthGuard component={CallbackPage} />}
            />
            <Route
              path=":orgname/offer/*"
              element={<AuthGuard component={OfferRedirection} />}
            />
            <Route
              path=":orgname/segments/*"
              element={<AuthGuard component={SegmentRedirection} />}
            />
            <Route
              path=":orgname/campaign/*"
              element={<AuthGuard component={CampaignRedirection} />}
            />
            <Route
              path="/"
              element={
                <Navigate to={"/auth0_org" + OFFER + DASHBOARD} replace />
              }
            />
            <Route
              path="/:org_name"
              element={
                <Navigate
                  to={
                    "/" +
                    window.location.pathname.split("/")[1] +
                    OFFER +
                    DASHBOARD
                  }
                  replace
                />
              }
            />
            <Route
              path=":orgname/dashboard"
              element={
                <Navigate
                  to={
                    "/" +
                    window.location.pathname.split("/")[1] +
                    OFFER +
                    DASHBOARD
                  }
                  replace
                />
              }
            />
            <Route
              path=":orgname/configure/*"
              element={<AuthGuard component={ConfigureRedirection} />}
            />
            <Route
              path=":orgname/configure/attribute/*"
              element={<AuthGuard component={ConfigureRedirection} />}
            />
            <Route
              path=":orgname/configure/attribute/*"
              element={
                <ConfigureRedirection component={ConfigureRedirection} />
              }
            />
            <Route
              path="*"
              Component={() => {
                return <PageNotFound pageName={"Page not found !"} />;
              }}
            />
            <Route
              path=":orgname/reports/*"
              element={<AuthGuard component={ReportsRedirection} />}
            />
            <Route
              path="/auth/logout"
              Component={() => {
                const url_org_Name = require("query-string").parse(
                  window.location.search
                ).orgName;
                window.location.replace(
                  window.location.origin +
                    (url_org_Name ? "/" + url_org_Name : "")
                );
              }}
            />
          </Routes>
          <Toaster
            position="top-right"
            autoDelete="true"
            autoDeleteTime={process.env.REACT_APP_TOASTER_DELETE_TIMEOUT}
          />           
        {(chatbotAlwaysShowStatus === "true")
        ?<ChatBotWidget />
        :(chatShowQryParam!==undefined)?<ChatBotWidget />:null}           
        </ErrorBoundary>             
      </Auth0ProviderWithNavigate>
    </Provider>
  </BrowserRouter>
);
